import classNames from 'classnames';
import TermOfUse from 'components/comachicart/ComachiTermOfUse';
import React, {useState} from 'react';
import Style from 'styles/components/comachicart/application.module.scss';

import {comachi_application_url} from '../../constants';

const Application = () => {
  // 利用規約を最後までスクロールされたかのフラグ
  const [isReadToBottom, setIsReadToBottom] = useState(false);
  // 利用規約への同意フラグ
  const [isAgreed, setIsAgreed] = useState(false);
  const [val, setVal] = React.useState(['js']);

  const checkBoxStyle = {
    opacity: isReadToBottom ? '1' : '0.3',
  };

  const scrollHander = e => {
    const remainingHeight =
      (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) / e.target.scrollHeight;
    if (remainingHeight <= 0.02) {
      // 下部4%までスクロールした場合に利用規約を読んだと判断
      setIsReadToBottom(true);
    }
  };
  const handleChange = e => {
    if (val.includes(e.target.value)) {
      setVal(val.filter(item => item !== e.target.value));
      setIsAgreed(false);
    } else {
      setVal([...val, e.target.value]);
      setIsAgreed(true);
    }
  };

  return (
    <section id='application' className={Style.application}>
      <div className={classNames(Style.application__inner, Style.mb50)}>
        <div className={classNames(Style.catch, Style.gap)}>
          <span>お申し込みはこちら</span>
        </div>
        <div className={Style.application__termsOfUse} id='scrollTermsOfUse' onScroll={scrollHander}>
          <span id='scrollTermsOfUseContent'>
            <TermOfUse />
          </span>
        </div>
        <div className={Style.application__checkbox} style={checkBoxStyle} id='checkAgree'>
          <input
            type = 'checkbox'
            value = 'checked'
            onChange = {handleChange} 
            checked = {val.includes('checked')} 
            disabled = {!isReadToBottom} 
            id = 'agreement' 
          ></input>
          <label htmlFor="agreement" className={Style.application__checkboxLabel}>
          <b>comachicartサービス利用規約</b>・<b>comachicartアプリストア及び当社アプリ利用の特約</b>・<b>プライバシーポリシー</b>に同意する
          </label>
        </div>
        <div>
          <div className={classNames(Style.voice, Style.purple, Style.fs20)}>
            「スタンダードプラン」へのお申し込みは
            <br />
          こちらのボタンをクリック</div>
        </div>
        <input
          type = 'button'
          onClick = {() => window.open(comachi_application_url, '_blank')} 
          value ='申し込む'
          className = {Style.application__button} 
          id = 'application'
          disabled={!isAgreed}
        />
      </div>
      <a href='/tokushoho/'>特定商取引法に基づく表記</a>
    </section>
  );
};

export default Application;
