import classNames from 'classnames';
import Image from 'components/image';
import React from 'react';
import Style from 'styles/components/comachicart/design.module.scss';

const Design = () => {
  const imagePrefix = 'comachi_lp/design/';
  return (
    <section className={Style.design}>
      <section>
        <h2 className={classNames(Style.catch)}>
          <span>選べるデザインテンプレート</span>
        </h2>
        <div className={classNames(Style.sub_catch)}>
          <span>初めての方でも、全体的に統一感を持ったデザインのショップを作ることができます。</span>
        </div>
        <div className={classNames(Style.design__template)}>
          <div className={classNames(Style.center, Style.fs16)}>
            <div>シンプルなものから、ポップや和テイストのものまで、</div>
            <div>ショップ全体のデザインを、かんたんに表現するテンプレート（ひな型）をご用意しています。</div>
          </div>
        </div>
        <div className={classNames(Style.m5cards, Style.center)}>
          <div className={classNames(Style.card, Style.center)}>
            <Image filename={imagePrefix + 'COOL.png'} />
            <div className={classNames(Style.fs20)}>COOL</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image filename={imagePrefix + "ORGANIC.png"} />
            <div className={classNames(Style.fs20)}>ORGANIC</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image filename={imagePrefix + 'POP.png'} />
            <div className={classNames(Style.fs20)}>POP</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image filename={imagePrefix + "SIMPLE.png"} />
            <div className={classNames(Style.fs20)}>SIMPLE</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image filename={imagePrefix + 'WA.png'} />
            <div className={classNames(Style.fs20)}>WA</div>
          </div>
        </div>
      </section>
      <section>
        <h2 className={classNames(Style.catch)}>
          <span>デザインパーツでかんたんカスタマイズ</span>
        </h2>
        <div className={classNames(Style.sub_catch)}>
          <span>パーツを組みあわせてオリジナルなショップページを作ることができます</span>
        </div>
        <div className={classNames(Style.m2cards, Style.design__custom)}>
          <Image classNames={Style.card} filename={imagePrefix + "parts.png"} />
          <div className={Style.card}>
            <div className={classNames(Style.sub_catch, Style.fs22)}>
              <div>プログラミングなどの専門知識は不要</div>
              <div>直観的操作で、かんたんカスタマイズ</div>
            </div>
            <div className={classNames(Style.center, Style.fs12)}>
              <div>
                スライドショーや画像・テキスト・動画など、「パーツを追加・移動するだけ」で、あなた好みのサイトの構成が出来上がります。
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <h3 className={classNames(Style.dogear, Style.center)}>
          <span>豊富なデザインパーツ</span>
        </h3>
        <div className={classNames(Style.m6cards, Style.center)}>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + 'text.png'} />
            <div className={classNames(Style.fs18)}>テキスト</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "image.png"} />
            <div className={classNames(Style.fs18)}>画像</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + 'video.png'} />
            <div className={classNames(Style.fs18)}>動画</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "title.png"} />
            <div className={classNames(Style.fs18)}>タイトル</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + 'slideshow.png'} />
            <div className={classNames(Style.fs18)}>スライドショー</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "share.png"} />
            <div className={classNames(Style.fs18)}>シェアボタン</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + 'button.png'} />
            <div className={classNames(Style.fs18)}>ボタン</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "sns.png"} />
            <div className={classNames(Style.fs18)}>SNSボタン</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + 'menu.png'} />
            <div className={classNames(Style.fs18)}>カテゴリメニュー</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "item.png"} />
            <div className={classNames(Style.fs18)}>商品一覧</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + 'news.png'} />
            <div className={classNames(Style.fs18)}>NEWS</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "hr.png"} />
            <div className={classNames(Style.fs18)}>区切り線</div>
          </div>
        </div>
      </section>
      <section>
        <h2 className={classNames(Style.catch)}>
          <span>他にも便利なデザインカスタマイズ機能が多数</span>
        </h2>
        <div className={classNames(Style.sub_catch)}>
          <span>
            テンプレートの背景画像やテキスト、メニューレイアウトの変更で
            <br />
            さらにあなた好みの見た目に
          </span>
        </div>
        <div className={classNames(Style.m2cards, Style.center)}>
          <div className={classNames(Style.card, Style.center)}>
            <Image filename={imagePrefix + 'change.png'} />
            <div className={classNames(Style.fs22)}>背景・テキスト変更</div>
            <div className={classNames(Style.fs16)}>
              テンプレートの背景画像を変更したり、テキストのフォントやカラーの変更が行えます。背景画像は、ショップユーザー様で用意された画像にも変更できますので、ショップの世界観を自由に表現することが可能です。
            </div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image filename={imagePrefix + 'layout.png'} />
            <div className={classNames(Style.fs22)}>メニューレイアウト変更</div>
            <div className={classNames(Style.fs16)}>
              メニューレイアウトの表示内容（メニュータイトル）やカラム（表示形式）、表示内容のテキストのカラー変更が行えます。
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Design;
